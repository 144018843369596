import React from 'react';
import { useState } from "react";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {Map, TileLayer, Marker, Popup, Polygon} from 'react-leaflet';
import Typography from "@material-ui/core/Typography";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../donka-location-icon-1-x2.png'),
    iconUrl: require('../donka-location-icon-1.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});



function Expositions() {

  const [locations, setLocations] = useState({
    lat: 50.878851,
    lng: 4.281446,
    zoom: 7,
    lat1: 50.8246263,
    lng1: 4.3282284,
    lat2: 50.4528,
    lng2: 4.405,
    lat3: 50.8134051,
    lng3: 5.1876273
  });

  const border = [
    [49.709650, 5.885013], // Gaichel
    [49.546418, 5.818410],// onderste punt belgie Athus
    [49.498335, 5.470709],// Velosnes
    [49.790839, 4.858822],// Bois de Bohan
    [50.144169, 4.889034],// Beauraing
    [50.020799, 4.144710],// Momignies
    [50.536022, 3.289698],// Crinquet
    [50.791116, 3.144536],// Menen
    [50.700744, 2.897344],// Kommen-Waasten
    [50.818888, 2.633672],// Poperingen
    [51.092248, 2.578740],// De Panne
    [51.364002, 3.361516],// Knokke-Heist
    [51.180141, 3.877874],// Wachtebeke
    [51.459935, 4.451909],// Essen
    [51.233485, 5.344549],// Lommel
    [51.159476, 5.825200],// Kinrooi
    [50.761590, 5.665899],// Wezet
    [50.334003, 6.388250],// Manderfeld
    [50.184749, 5.937810],// Gouvy

  ]


  const polygon =  [
    [[90, -180],
 [90, 180],
 [-90, 180],
 [-90, -180]], // outer ring
    [[
      [49.709650, 5.885013], // Gaichel
      [49.546418, 5.818410],// onderste punt belgie Athus
      [49.498335, 5.470709],// Velosnes
      [49.790839, 4.858822],// Bois de Bohan
      [50.144169, 4.889034],// Beauraing
      [50.020799, 4.144710],// Momignies
      [50.536022, 3.289698],// Crinquet
      [50.791116, 3.144536],// Menen
      [50.700744, 2.897344],// Kommen-Waasten
      [50.818888, 2.633672],// Poperingen
      [51.092248, 2.578740],// De Panne
      [51.364002, 3.361516],// Knokke-Heist
      [51.180141, 3.877874],// Wachtebeke
      [51.459935, 4.451909],// Essen
      [51.233485, 5.344549],// Lommel
      [51.159476, 5.825200],// Kinrooi
      [50.761590, 5.665899],// Wezet
      [50.334003, 6.388250],// Manderfeld
      [50.184749, 5.937810],// Gouvy
    ]] // hole
  ];

  // const onClickMarker = (e) => {

  //   setLocations({
  //     lat: 50.878851,
  //     lng: 4.281446,
  //     zoom: 10,
  //     lat1: 50.8246263,
  //     lng1: 4.3282284,
  //     lat2: 50.852247,
  //     lng2: 4.3,
  //   })
  // }



    const position = [locations.lat, locations.lng];
    const position2 = [locations.lat1, locations.lng1];
    const position1 = [locations.lat2, locations.lng2];
    const position3 = [locations.lat3, locations.lng3];
    
    return (
      <Map center={position1} zoom={locations.zoom}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />

<Marker position={position}>
          <Popup>
          <Typography align="center" variant="h6" display="block">Volvo De Smet</Typography>
          <Typography align="center" variant="caption" display="block">weekdagen 8u-18u30<br/>zaterdag 10u-17u<br/>zondag gesloten</Typography>
          <Typography align="center" variant="body2" >Brusselsesteenweg 605<br/>1731 Zellik</Typography>
          </Popup>
        </Marker>
<Marker position={position2}>
          <Popup>
          <Typography align="center" variant="h6" display="block">Atelier</Typography>
          <Typography align="center" variant="caption" display="block">ENKEL OP AFSPRAAK</Typography>
          <Typography align="center" variant="body2" >Berthelotstraat 121<br/>1190 Vorst</Typography>
          </Popup>
        </Marker>
<Marker position={position3}>
          <Popup>
          <Typography align="center" variant="h6" display="block" style={{lineHeight: 1, marginBottom: '7px'}}>Cultuurcentrum<br/>de Bogaard</Typography>
          <Typography align="center" variant="body2" style={{marginTop:0, marginBottom:'9px'}}>1 maart - 28 april 2021</Typography>
          <Typography align="center" variant="caption" display="block">ma tot vr: 12u-18u, za: 10u-16u<br/>Gesloten op 15/04</Typography>
          <Typography align="center" variant="body2" style={{marginTop:'9px'}}>Capucienessenstraat 8<br/>3800 Sint-Truiden</Typography>
          </Popup>
        </Marker>
        <Polygon color="white" positions={polygon} fillOpacity={1} />
        <Polygon color="black" positions={border} fillOpacity={0} weight={2} />
        </Map>
    );

}


export default Expositions;