export default [
  {
    width:728, height:687,
    src: "https://eletteric.com/donka/04-donka.jpg",
    description:"In de wolken - Vooravond",

  },
  {
    width:655, height:1510,
    src: "https://eletteric.com/donka/05-donka.jpg",
    description:"Marylin gaat naar zee - lakverf acryl op sticker op pvc - 31 x 75cm",

  },
  {
    width:770, height:502,
    src: "https://eletteric.com/donka/03-donka.jpg",
    description:"Zonsopgang - acryl lakverf en marker op papier",

  },
  {
    width:168, height:160,
    src: "https://eletteric.com/donka/02-donka.jpg",
    description:"In de wolken – Valavond",

  },
  {
    width:772, height:620,
    src: "https://eletteric.com/donka/06-donka.jpg",
    description:"Roze wolk - acryl op karton",

  },
  {
    width:878, height:628,
    src: "https://eletteric.com/donka/07-donka.jpg",
    description:"Zonsondergang – acryl op papier – 129 x 90cm",

  },
  {
    width:894, height:490,
    src: "https://eletteric.com/donka/08-donka.jpg",
    description:"Another love story – acryl marker op papier",

  },
  {
    width:212, height:140,
    src: "https://eletteric.com/donka/09-donka.jpg",
    description:"In de wolken - middag",

  },
  {
    width:106, height:80,src: "https://eletteric.com/donka/01-donka.jpg",
    description:"Ter land, ter zee en in de lucht – acryl op karton - 80 x 60cm",
  },
];
