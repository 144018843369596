import React from "react";
import ReactDOM from "react-dom";

import Gallery from "react-photo-gallery";
import { useState, useEffect } from "react";
import Quotes from "./components/Quotes.js";
import Expositions from "./components/Expositions.js";

import data from "./data";
import "./styles.css";
import "./App.css";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CloseIcon from "@material-ui/icons/Close";
import InstagramIcon from "@material-ui/icons/Instagram";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Dialog from "@material-ui/core/Dialog";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Avatar from "@material-ui/core/Avatar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useSpring, animated } from "react-spring";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  stickToBottom: {
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "30px",
    left: "0",
    top: "auto",
    bottom: 0,
    background: "#fff",
  },
  infoDialog: {
    textAlign: "center",
  },
  mapContainer: {
    height: "320px",
    width: "100%",
  },
  portrait: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  navIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: "#333",
    marginBottom: "2px",
    lineHeight: "3em",
  },
  closeDialog: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    margin: 0,
    padding: 0,
    marginTop: "10px",
    opacity: 0.45,
  },
  topBar: {
    background: "#fff",
    height: "70px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  menuButton: {
    padding: "15px 20px 15px 20px",
  },
  buttonRipple: {
    color: "#333",
    opacity: 0.5,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});

function App() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [items] = useState(data);

  const AnimatedLogo = (props) => {
    const don = useSpring({
      i: 0,
      from: { i: 525 },
      delay: 10,
      config: { duration: 550 },
    });
    const ka = useSpring({
      y: 0,
      from: { y: 175 },
      delay: 720,
      config: { duration: 150 },
    });
    const kaka = useSpring({
      z: 0,
      from: { z: 190 },
      delay: 950,
      config: { duration: 400 },
    });

    return (
      <React.Fragment>
        <svg
          onClick={() => {
            setValue(0);
          }}
          style={{ cursor: "pointer", outline: 0 }}
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width={props.width}
          height={props.height}
          viewBox="0 0 260 160"
          enableBackground="new 0 0 240 160"
          xmlSpace="preserve"
        >
          <g>
            <animated.path
              strokeDashoffset={don.i}
              fill="none"
              strokeWidth="7.7"
              stroke="rgb(0, 0, 0)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="530"
              d="
	M39.647,12.8c-0.058,0.902-0.097,1.828-0.111,2.766c-0.135,8.658-2.497,21.709,13.475,38.451l0.349,0.351
	c9.707,8.823,19.527,17.111,18.352,37.112c-0.429,6.365,0.084,19.736-15.705,33.605c-5.197,2.395-15.975,6.303-13.365-14.184
	c1.176-10.295,6.867-18.297,12.161-25.355c5.294-7.059,18.435-25.336,27.524-17.705c10.019,8.412,19.633,53.348,10.548,72.4
	c-5.15,11.043-16.017,1.629-15.92-8.686c0.098-10.314,6.025-19.962,10.225-28.481c4.741-9.616,14.929-21.062,19.548-6.392
	c8.715,35.172,10.961-2.282,10.961-2.282c3.26-22.143,18.669-14.206,16.774,6.149"
            />
            <animated.path
              strokeDashoffset={ka.y}
              fill="none"
              strokeWidth="7.7"
              stroke="rgb(0, 0, 0)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="170"
              d="M145.016,16.942
	c0.624,6.936,1.27,16.348,1.789,28.945c0.759,17.929,0.737,27.44,1.372,46.979c0.637,19.539,0.503,22.004,2.299,47.229"
            />
            <animated.path
              strokeDashoffset={kaka.z}
              fill="none"
              strokeWidth="7.7"
              stroke="rgb(0, 0, 0)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="185"
              d="
	M172.597,63.741c0,0-0.464,2.896-1.558,4.379c-1.057,1.428-8.586,21.212-26.716,24.847l0.319-0.475
	c8.258-2.693,18.443,1.38,22.117,2.91c3.675,1.531,14.945,5.989,19.925-2.7c2.415-4.556,7.209-14.529,7.094-24.89
	c6.536,16.3,8.254,27.199,12.885,31.54"
            />
          </g>
        </svg>
      </React.Fragment>
    );
  };

  const titleOpacity = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 10,
  });

  const openLightbox = (event, obj) => {
    setPhotoIndex(obj.index);
    setValue(1000);
    setIsOpen(true);
  };
  const handleClose = () => {
    setValue(null);
  };

  const logoAnimation = () => {
    return (
      <div className="google-map-code">
        <h1>hier komt een map</h1>{" "}
      </div>
    );
  };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="App">
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255,255,255,0.9)",
            boxShadow: "none",
            overflow: "visible",
            padding: "20px",
          },
        }}
        open={value === 0}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <AnimatedLogo height="160px" width="280px" />
        <div style={{ width: "100%" }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              padding: 0,
              margin: "-10px 0px 0px 0px",
              fontSize: "0.9em",
              textAlign: "center",
              width: "70%",
              float: "right",
              display: "inline-block",
              position: "relative",
              fontFamily: "Roboto",
              fontWeight: "700",
              fontVariant: ["small-caps"],
              fontStyle: "italic",
              color: "#333",
            }}
          >
            Anthony Mary
          </Typography>
        </div>
        <div className={classes.closeDialog}>
          <Button onClick={handleClose} disableRipple style={{ color: "#000" }}>
            <CloseIcon />
          </Button>
        </div>
      </Dialog>
      <div>
        <Gallery
          margin={12}
          columns={2}
          photos={items}
          onClick={openLightbox}
        />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={items[photoIndex].src}
          nextSrc={items[(photoIndex + 1) % items.length].src}
          prevSrc={items[(photoIndex + items.length - 1) % items.length].src}
          imageCaption={items[photoIndex].description}
          onCloseRequest={() => {
            setIsOpen(false);
            setValue(null);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % items.length)
          }
        />
      )}

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255,255,255,0.9)",
            boxShadow: "none",
            overflow: "hidden",
            padding: "30px",
          },
        }}
        open={value === 1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.infoDialog}>
          <div className={classes.portrait}>
          <img src='/anthony-mary.jpg' style={{maxWidth:'100%'}}/>
          </div>
          <Typography variant="h5" gutterBottom>
            Anthony Mary - Mr.&nbsp;Donka
          </Typography>
          <Typography variant="overline" display="block" gutterBottom style={{marginBottom:'10px'}}>
            Contact informatie:
          </Typography>
          <Grid
            spacing={isSmall ? 1 : 5}
            container
            direction="row"
            alignItems="stretch"
            justify="center"
            alignContent="center"
          >
            <Grid item>
              <Grid
                spacing={0}
                container
                direction="row"
                alignItems="stretch"
                justify="center"
                alignContent="center"
              >
                <Grid item>
                  <MailOutlineIcon style={{ marginRight: "7px" }} />
                </Grid>
                <Grid item>
                  <Link
                    style={{
                      textDecoration: "underline",
                      marginBottom: "15px",
                    }}
                    href="mailto:info@mrdonka.be"
                    color="inherit"
                  >
                    {"info@mrdonka.be"}
                  </Link>
                </Grid>
              </Grid>
              <Grid
                spacing={0}
                container
                direction="row"
                alignItems="stretch"
                justify="center"
                alignContent="center"
              >
                <Grid item>
                  <PhoneIcon style={{ marginRight: "-1px" }} />
                </Grid>
                <Grid item>
                  <Link
                    style={{ textDecoration: "none" }}
                    href="tel:+32493-64-96-11"
                    color="inherit"
                  >
                    {"+32493649611"}
                  </Link>{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                spacing={0}
                container
                direction="row"
                alignItems="stretch"
                justify="center"
                alignContent="center"
              >
                <Grid item>
                  <InstagramIcon style={{ marginRight: "7px" }} />
                </Grid>
                <Grid item>
                  <Link
                    style={{ textDecoration: "underline" }}
                    href="https://www.instagram.com/mrdonka/"
                    color="inherit"
                  >
                    {"@mrdonka"}
                  </Link>{" "}
                </Grid>
              </Grid>
              <Grid
                spacing={0}
                container
                direction="row"
                alignItems="stretch"
                justify="center"
                alignContent="center"
              >
                <Grid item>
                  <FacebookIcon style={{ marginRight: "7px" }} />
                </Grid>
                <Grid item>
                  <Link
                    style={{ textDecoration: "underline" }}
                    href="https://www.facebook.com/mmrdonka"
                    color="inherit"
                  >
                    {"mmrdonka"}
                  </Link>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid
            spacing={0}
            container
            direction="row"
            alignItems="stretch"
            justify="center"
            alignContent="center"
            style={{ marginLeft: "-5px" }}
          >
            <Grid item>
              <ChevronRightOutlinedIcon style={{ marginRight: "-3px" }} />
            </Grid>
            <Grid item>
              <Link
                style={{ textDecoration: "underline" }}
                href="https://mmrdonka.bigcartel.com/"
                color="inherit"
              >
                {"shop online"}
              </Link>{" "}
            </Grid>
          </Grid>
          <Typography
            variant="overline"
            align="center"
            display="block"
            style={{ marginTop: "15px", marginBottom: 0, lineHeight: 1.5 }}
          >
            Atelier Donka, Brussel
          </Typography>
          <Typography
            variant="overline"
            align="center"
            display="block"
            gutterBottom
            style={{ lineHeight: 1.5 }}
          >
            BTW BE 0630.790.505<br/>
          </Typography>
          <div className={classes.closeDialog}>
            <Button
              onClick={handleClose}
              disableRipple
              style={{ color: "#000" }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255,255,255, 0.9)",
            boxShadow: "none",
            overflow: "hidden",
            padding: "0px",
            paddingTop: "5px",
            width: "100%",
          },
        }}
        open={value === 2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography align="center" variant="h6" display="block" gutterBottom style={{marginTop:'10px', marginBottom:'5px'}}>
          Expos
        </Typography>
        <div className={classes.mapContainer}>
          <Expositions />
        </div>
        <div className={classes.closeDialog}>
            <Button
              onClick={handleClose}
              disableRipple
              style={{ color: "#000", marginBottom:'10px'}}
            >
              <CloseIcon />
            </Button>
          </div>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255,255,255,0.9)",
            boxShadow: "none",
            overflowX: "hidden",
            overflowY: "scroll",
            padding: "20px",
            width: "100%",
          },
        }}
        open={value === 3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography align="center" variant="h6" display="block" gutterBottom>
          Biografie
        </Typography>
        <Typography align="left" variant="body2" display="block" gutterBottom>
          Anthony Mary beter bekend als Mr. Donka, is een Belgische schilder en
          straatartiest. Hij werd in 1982 geboren in Anderlecht, Brussel,
          België. Tegenwoordig woont de kunstenaar in Vorst, waar ook zijn
          atelier gelegen is. Van kinds af tekende hij in de kantlijnen van zijn
          cursussen omdat hij aso moest volgen van zijn vader. Het aso werd
          afgewerkt, het tekenen bleef. Na zijn humaniora maakte hij een kort
          ommetje in Sint-Lucas van welgeteld zes maanden. Het was niets voor
          hem. Na paar jaar op een laag pitje te draaien brak hij zijn rug. Na
          de herstelperiode was de kunst terug als uitlaatklep, een uitweg om
          aan het werk te gaan.
          <br />
          <br />
          Creatief zijn denken, bedenken zijn dingen die als vanzelf komen,
          kijken is werken. Na een jaar elke dag schetsen is hij zijn werken
          groter gaan maken. Toen was hij eindelijk klaar voor zijn eerste
          tentoonstelling in het Kultuurkaffee aan de VUB. Sindsdien is de
          sneeuwbal min of meer aan het rollen. Hij is eerder een schilder ook
          al durft hij met de spuitbus ook wel eens te werk gaan. Beeldhouwen is
          eveneens een passie.
          <br />
          <br />
          Je kan zijn werk bewonderen op zijn instagram <Link
                    style={{
                      textDecoration: "underline",
                    }}
                    href="https://www.instagram.com/mrdonka/"
                    target="_blank"
                    color="inherit"
                  >www.instagram.com/mrdonka/</Link> met niet minder dan 3500 posts of op zijn
          website <Link
                    style={{
                      textDecoration: "underline",
                    }}
                    href="/"
                    color="inherit"
                  >www.mrdonka.be</Link>. Je kan een werk online kopen op <Link
                    style={{
                      textDecoration: "underline",
                    }}
                    href="https://mmrdonka.bigcartel.com/"
                    target="_blank"
                    color="inherit"
                  >mmrdonka.bigcartel.com</Link>.
          <br />
          <br />
          Self taught artist Zijn eerste werken dateren van 2002-2010 onder zijn
          eigen naam. Schilderijen muurschilderingen etsen… Sindsdien gebruikt
          hij Mr Donka waar hij nu 8 jaar voltijds mee bezig is 8 dagen op 7
          schilderen beeldhouwen en muurschilderingen maken. Zijn kleurenpalet
          word niet zelden bepaald door de verf die voorradig is, een beetje
          zoals koken met de restjes uit de koelkast. Hij raapt dingen op van de
          grond en verwerkt ze later in zijn werken. Afval is er niet,
          mogelijkheden zijn er; gebruik, hergebruik... hij is tegen
          verspilling.
          <br />
          <br />
          Het maakt niet uit wat de drager is, Mr Donka past zich aan aan de
          werkomstandigheden. Creatief zijn, oplossingen vinden on the spot, het
          is steeds een uitdaging. “Je werkuren tel je niet als kunstenaar, het
          is met plezier gedaan.” Hij houdt van woordspelingen, versprekingen,
          dubbele betekenissen, ... het oog wil wat maar de titel doet mee, soms
          is de titel het werk. Love is a Battlefield, het is een leidraad door
          vele werken van hem, het verhaal, de zoektocht naar liefde afgebeeld
          in een bom van informatie, details doen er wel degelijk toe, een blik
          van ver, maar een blik van dichtbij is soms even belangrijk.
          <br />
          <br />
          Tegenwoordig zijn de schilderijen van MrDonka zeer kleurrijk terwijl
          hij vroeger meer in zwart wit deed, het is een verademing. Hij heeft
          tentoonstellingen gedaan in: Kultuurkaffe (2014), Lennik Dekenij
          (2015), Destelheide Dworp (2017), Polis (2018), Volvo Desmet Zellik
          (2020). Hij werkte mee aan projecten in het Pajottenland en Brussel:
          MATCH, de gronckel lennikse kunstenaars; 25 jaar prins de trots van
          Brabant inzending Boerenpaard; konijnenhok van vzw Levenslust in
          Lennik; fresco in het opvangcentrum Ad Home in Vorst voor
          jongeren(18-24) zonder thuis. MrDonka maakte fresco’s in Ruisbroek
          electriciteitskast, urban spaces hotel ibiza , een volledige kamer en
          streetart in Brussel stad.
          <br />
          <br />
          Zijn werken refereren o.a. naar de kunstgeschiedenis zoals naar
          Vermeers ‘Meisje met de parel’. En wolken standen van de zon zonlicht
          die verwijzen naar de hoop het geluk de rijkdom de vrijheid juist
          achter de hoek.
        </Typography>
        <div className={classes.closeDialog}>
            <Button
              onClick={handleClose}
              disableRipple
              style={{ color: "#000", marginBottom:'30px' }}
            >
              <CloseIcon />
            </Button>
          </div>
      </Dialog>
      <Slide direction="up" in={value === null}>
        <AppBar position="fixed" className={classes.stickToBottom}>
          <Grid
            spacing={0}
            container
            direction="row-reverse"
            alignItems="flex-end"
            justify="center"
            alignContent="flex-end"
          >
            <Grid item xs={12} sm={12} md={6}>
              <AnimatedLogo height="60px" width="120px" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ButtonGroup
                variant="text"
                aria-label="text secondary button group"
              >
                <Button
                  TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                  className={classes.menuButton}
                  size="small"
                  onClick={() => {
                    setValue(3);
                  }}
                >
                  Biografie
                </Button>
                <Button
                  TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                  className={classes.menuButton}
                  size="small"
                  onClick={() => {
                    setValue(1);
                  }}
                >
                  Contact
                </Button>
                <Button
                  TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
                  className={classes.menuButton}
                  size="small"
                  onClick={() => {
                    setValue(2);
                  }}
                >
                  Expos
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </AppBar>
      </Slide>
    </div>
  );
}

export default App;
